import React from "react"

const Share = () => {
  return (
    <div className="share">
      <ul>
        <li className="share-facebook">
          <a href="https://www.facebook.com/sharer/sharer.php?u=https://super-productivity.com/">
            <div className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="xMidYMid"
                width="29"
                height="29"
                viewBox="0 0 29 29"
              >
                <path
                  d="M26.4 0H2.6C1.714 0 0 1.715 0 2.6v23.8c0 .884 1.715 2.6 2.6 2.6h12.393V17.988h-3.996v-3.98h3.997v-3.062c0-3.746 2.835-5.97 6.177-5.97 1.6 0 2.444.173 2.845.226v3.792H21.18c-1.817 0-2.156.9-2.156 2.168v2.847h5.045l-.66 3.978h-4.386V29H26.4c.884 0 2.6-1.716 2.6-2.6V2.6c0-.885-1.716-2.6-2.6-2.6z"
                  className="cls-2"
                ></path>
              </svg>
            </div>
            <div className="text">facebook</div>
          </a>
        </li>
        <li className="share-linkedin">
          <a href="http://www.linkedin.com/shareArticle?mini=true&amp;url=https://super-productivity.com&amp;title=I%20like%20Super%20Productivity&amp;">
            <div className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
              >
                <path d="M25.424 15.887v8.447h-4.896v-7.882c0-1.98-.71-3.33-2.48-3.33-1.354 0-2.158.91-2.514 1.802-.13.315-.162.753-.162 1.194v8.216h-4.9s.067-13.35 0-14.73h4.9v2.087c-.01.017-.023.033-.033.05h.032v-.05c.65-1.002 1.812-2.435 4.414-2.435 3.222 0 5.638 2.106 5.638 6.632zM5.348 2.5c-1.676 0-2.772 1.093-2.772 2.54 0 1.42 1.066 2.538 2.717 2.546h.032c1.71 0 2.77-1.132 2.77-2.546C8.056 3.593 7.02 2.5 5.344 2.5h.005zm-2.48 21.834h4.896V9.604H2.867v14.73z"></path>
              </svg>
            </div>
            <div className="text">linkedin</div>
          </a>
        </li>
        <li className="share-twitter">
          <a href="https://twitter.com/intent/tweet?text=I%20like%20Super%20Productivity%20%20https%3A%2F%2Fsuper-productivity.com">
            <div className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
              >
                <path d="M24.253 8.756C24.69 17.08 18.297 24.182 9.97 24.62c-3.122.162-6.22-.646-8.86-2.32 2.702.18 5.375-.648 7.507-2.32-2.072-.248-3.818-1.662-4.49-3.64.802.13 1.62.077 2.4-.154-2.482-.466-4.312-2.586-4.412-5.11.688.276 1.426.408 2.168.387-2.135-1.65-2.73-4.62-1.394-6.965C5.574 7.816 9.54 9.84 13.802 10.07c-.842-2.738.694-5.64 3.434-6.48 2.018-.624 4.212.043 5.546 1.682 1.186-.213 2.318-.662 3.33-1.317-.386 1.256-1.248 2.312-2.4 2.942 1.048-.106 2.07-.394 3.02-.85-.458 1.182-1.343 2.15-2.48 2.71z"></path>
              </svg>
            </div>
            <div className="text">twitter</div>
          </a>
        </li>
        <li className="share-reddit">
          <a href="http://www.reddit.com/submit?url=https%3A%2F%2Fsuper-productivity.com&title=I%20like%20Super%20Productivity">
            <div className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                className="_1O4jTk-dZ-VIxsCuYB6OR8"
              >
                <g>
                  <circle fill="#FF4500" cx="10" cy="10" r="10"></circle>
                  <path
                    fill="#FFF"
                    d="M16.67,10A1.46,1.46,0,0,0,14.2,9a7.12,7.12,0,0,0-3.85-1.23L11,4.65,13.14,5.1a1,1,0,1,0,.13-0.61L10.82,4a0.31,0.31,0,0,0-.37.24L9.71,7.71a7.14,7.14,0,0,0-3.9,1.23A1.46,1.46,0,1,0,4.2,11.33a2.87,2.87,0,0,0,0,.44c0,2.24,2.61,4.06,5.83,4.06s5.83-1.82,5.83-4.06a2.87,2.87,0,0,0,0-.44A1.46,1.46,0,0,0,16.67,10Zm-10,1a1,1,0,1,1,1,1A1,1,0,0,1,6.67,11Zm5.81,2.75a3.84,3.84,0,0,1-2.47.77,3.84,3.84,0,0,1-2.47-.77,0.27,0.27,0,0,1,.38-0.38A3.27,3.27,0,0,0,10,14a3.28,3.28,0,0,0,2.09-.61A0.27,0.27,0,1,1,12.48,13.79Zm-0.18-1.71a1,1,0,1,1,1-1A1,1,0,0,1,12.29,12.08Z"
                  ></path>
                </g>
              </svg>
            </div>
            <div className="text">twitter</div>
          </a>
        </li>

        <li className="share-devto">
          <a href="https://dev.to/new?prefill=---%0Atitle%3A%20I%20like%20Super%20Productivity%0A---%0A%0Ahttps%3A%2F%2Fsuper-productivity.com">
            <div className="icon">
              <svg
                className="native svg-icon iconDevTo"
                aria-hidden="true"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M15 1a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3c0-1.1.9-2 2-2h12zm-3 5.02h-1.18l1.4 5.24c.36.86 1.16 1.1 1.65.13l.06-.13 1.39-5.24h-1.18l-1.07 4.12L12 6.02zm-1.96 0H7.75a.72.72 0 0 0-.7.65l-.01.1v4.53c0 .37.29.67.65.7l.1.01h2.25v-1.07H8.1V9.26h1.18V8.19H8.1v-1.1h1.93V6.02zm-5.32 0H3v5.99h1.68c1.33 0 1.75-1.03 1.75-1.71V7.73c0-.68-.43-1.71-1.71-1.71zm.06 1.12a.7.7 0 0 1 .42.16c.14.1.21.26.21.47v2.52c0 .21-.07.37-.2.47a.7.7 0 0 1-.43.16h-.63V7.14h.63z"
                  fill="#010101"
                ></path>
              </svg>
            </div>
            <div className="text">twitter</div>
          </a>
        </li>
        <li className="share-github">
          <a href="https://github.com/johannesjo/super-productivity">
            <div className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
              >
                <path d="M13.97 1.57c-7.03 0-12.733 5.703-12.733 12.74 0 5.622 3.636 10.393 8.717 12.084.637.13.87-.277.87-.615 0-.302-.013-1.103-.02-2.165-3.54.77-4.29-1.707-4.29-1.707-.578-1.473-1.413-1.863-1.413-1.863-1.154-.79.09-.775.09-.775 1.276.104 1.96 1.316 1.96 1.312 1.135 1.936 2.99 1.393 3.712 1.06.116-.823.445-1.384.81-1.704-2.83-.32-5.802-1.414-5.802-6.293 0-1.39.496-2.527 1.312-3.418-.132-.322-.57-1.617.123-3.37 0 0 1.07-.343 3.508 1.305 1.016-.282 2.105-.424 3.188-.43 1.082 0 2.167.156 3.198.44 2.43-1.65 3.498-1.307 3.498-1.307.695 1.754.258 3.043.13 3.37.815.903 1.314 2.038 1.314 3.43 0 4.893-2.978 5.97-5.814 6.286.458.388.876 1.16.876 2.358 0 1.703-.016 3.076-.016 3.482 0 .334.232.748.877.61 5.056-1.687 8.7-6.456 8.7-12.08-.055-7.058-5.75-12.757-12.792-12.75z"></path>
              </svg>
            </div>
            <div className="text">github</div>
          </a>
        </li>
      </ul>
    </div>
  )
}

export default Share
