import React from "react"

const CalToActionBtns = ({ isDark = false }: { isDark?: boolean }) => (
  <div className={`call-to-action-btns ${isDark ? "isDark" : ""}`}>
    <a href="https://app.super-productivity.com">
      <i className="icon">language</i>
      <span>web app</span>
    </a>
    <a
      href="https://github.com/johannesjo/super-productivity/releases"
      className="js-app-download-link"
    >
      <i className="icon">file_download</i>
      <span>download</span>
    </a>
    <a
      href="https://github.com/johannesjo/super-productivity"
      className="js-app-download-link hide-xs"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="28"
        viewBox="0 0 28 28"
      >
        <path d="M13.97 1.57c-7.03 0-12.733 5.703-12.733 12.74 0 5.622 3.636 10.393 8.717 12.084.637.13.87-.277.87-.615 0-.302-.013-1.103-.02-2.165-3.54.77-4.29-1.707-4.29-1.707-.578-1.473-1.413-1.863-1.413-1.863-1.154-.79.09-.775.09-.775 1.276.104 1.96 1.316 1.96 1.312 1.135 1.936 2.99 1.393 3.712 1.06.116-.823.445-1.384.81-1.704-2.83-.32-5.802-1.414-5.802-6.293 0-1.39.496-2.527 1.312-3.418-.132-.322-.57-1.617.123-3.37 0 0 1.07-.343 3.508 1.305 1.016-.282 2.105-.424 3.188-.43 1.082 0 2.167.156 3.198.44 2.43-1.65 3.498-1.307 3.498-1.307.695 1.754.258 3.043.13 3.37.815.903 1.314 2.038 1.314 3.43 0 4.893-2.978 5.97-5.814 6.286.458.388.876 1.16.876 2.358 0 1.703-.016 3.076-.016 3.482 0 .334.232.748.877.61 5.056-1.687 8.7-6.456 8.7-12.08-.055-7.058-5.75-12.757-12.792-12.75z"></path>
      </svg>
      <span>hack</span>
    </a>
  </div>
)
export default CalToActionBtns
